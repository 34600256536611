// src/services/getMetaInformation.js
export const getMetaInformation = async ({ propertySlug }) => {
	const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

	try {
		const response = await fetch(`${apiBaseUrl}/api/v1/properties/with-meta-information/${propertySlug}`);
		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || "Failed to fetch meta information");
		}
		return await response.json();
	} catch (error) {
		throw error; // Re-throw the error to handle it in the hook's onError
	}
};
