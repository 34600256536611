// src/hooks/queries/useMetaInformationQuery.js
import { useQuery } from "@tanstack/react-query";
import { getMetaInformation } from "@/services/getMetaInformation";
import { logErrorToServer } from "@/utils/logErrorToServer";

export const useMetaInformationQuery = () => {
	const propertySlug = process.env.NEXT_PUBLIC_PROPERTY_SLUG;

	return useQuery({
		queryKey: ["metaInformation", propertySlug],
		queryFn: () => getMetaInformation({ propertySlug }),
		enabled: !!propertySlug,
		staleTime: 60 * 60 * 1000, // 60 minutes in milliseconds
		cacheTime: 5 * 60 * 1000, // 5 minutes in milliseconds
		onError: async (error) => {
			// Log the error to the server
			const errorDetails = {
				message: error.message || "An unknown error occurred",
				stack: error.stack || "No stack trace available",
				type: "Fetch Error",
				additionalInfo: { detail: "Error while fetching meta information" },
			};

			await logErrorToServer(errorDetails);
		},
	});
};
