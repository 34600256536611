// utils/logErrorToServer.js

import { shouldLogError } from "@/utils/errorLogDebouncer.js";

export const logErrorToServer = async ({ message, stack, type, additionalInfo, userAgent, url }) => {
	const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
	const domainName = process.env.NEXT_PUBLIC_DOMAIN_NAME;

	if (!message || !stack || !type) {
		// Can't hit POST below if we don't have key values
		return null;
	}

	if (!shouldLogError(message)) {
		return null;
	}

	// Array of specific error messages to check
	const specificErrors = [
		`Resource failed to load: ${process.env.NEXT_PUBLIC_BASE_URL}/js/gtag.js`,
		"Resource failed to load: undefined", //I was getting this error on the suites page on mobile but it seemed to work fine... couldn't find where the error was coming from so I just turned it off
		"Resource failed to load:",
		// Add more specific error messages here as needed
	];

	const isSpecificError = specificErrors.some((error) => message.includes(error));

	if (isSpecificError) {
		// Ignore this specific error
		return null;
	}

	try {
		const apiUrl = `${apiBaseUrl}/api/v1/fallback-errors`;

		const userAgentFinal = userAgent || (typeof navigator !== "undefined" ? navigator.userAgent : "Unknown");
		const urlFinal = url || (typeof window !== "undefined" ? window.location.href : "Unknown");

		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				// Does not require access token
			},
			body: JSON.stringify({
				error_message: message,
				error_stack: stack,
				error_type: type,
				user_agent: userAgentFinal, // Now safely defaults to 'Unknown' if not in a browser
				url: urlFinal, // Now safely defaults to 'Unknown' if not in a browser
				domain_name: domainName, // Include the domain name in the request
				additional_info: JSON.stringify(additionalInfo || {}), // Ensure additional info is a stringified JSON object
			}),
		});

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
		return await response.json();
	} catch (error) {
		return null; // Explicitly return null (for consistent-return)
	}
};
