// components/feedback/ErrorMessage.js
import React from "react";

const ErrorMessage = ({ message = "An unexpected error occurred. Please try again later." }) => {
	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-100">
			<div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
				<h2 className="text-2xl font-semibold text-gray-700 mb-4">Oops!</h2>
				<p className="text-gray-600">{message}</p>
				<button
					onClick={() => window.location.reload()}
					className="mt-6 bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-150 ease-in-out"
				>
					Refresh Page
				</button>
			</div>
		</div>
	);
};

export default ErrorMessage;
