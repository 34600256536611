// pages/_app.js
import "@/styles/globals.css";
import { QueryClient, QueryClientProvider, hydrate } from "@tanstack/react-query";
import PageLayout from "@/components/layout/PageLayout";
import { useState, useEffect } from "react";
import useGlobalErrorHandler from "@/hooks/useGlobalErrorHandler";
import ErrorBoundary from "@/components/feedback/ErrorBoundary";

function MyApp({ Component, pageProps }) {
	const [queryClient] = useState(() => new QueryClient());
	const [nonce, setNonce] = useState("");

	useEffect(() => {
		const metaNonce = document.querySelector("meta[name='csp-nonce']");
		if (metaNonce) {
			setNonce(metaNonce.getAttribute("content"));
		}
	}, []);

	// Hydrate the query client with the initial state if it exists
	useEffect(() => {
		if (pageProps.dehydratedState) {
			hydrate(queryClient, pageProps.dehydratedState);
		}
	}, [pageProps.dehydratedState, queryClient]);

	// Use the custom hook to set up global error handling
	useGlobalErrorHandler();

	return (
		<QueryClientProvider client={queryClient}>
			<PageLayout nonce={nonce}>
				<ErrorBoundary>
					<Component {...pageProps} />
				</ErrorBoundary>
			</PageLayout>
		</QueryClientProvider>
	);
}

export default MyApp;
