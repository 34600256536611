// hooks/useFormSubmission.js
import { useState } from "react";
import { logErrorToServer } from "@/utils/logErrorToServer";

export const useFormSubmission = ({ apiEndpoint, onSuccess }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [error, setError] = useState(null);

	const submitForm = async (data) => {
		setIsSubmitting(true);
		setError(null);

		try {
			const response = await fetch(apiEndpoint, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});

			if (response.ok) {
				onSuccess();
			} else {
				throw new Error("Failed to submit form");
			}
		} catch (err) {
			console.error("Error submitting form:", err);
			setError("An error occurred. Please try again.");
			await logErrorToServer({
				message: err.message,
				stack: err.stack,
				type: "Form Submission Error",
				userAgent: navigator.userAgent,
				url: window.location.href,
				additionalInfo: {
					apiEndpoint,
				},
			});
		} finally {
			setIsSubmitting(false);
		}
	};

	return { submitForm, isSubmitting, error };
};
